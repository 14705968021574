.action-items-tabs-container {
    width: 420px;
    @apply rounded-lg flex bg-gray-300 mt-6 p-1;
}

@media screen and (max-width: 1023px) {
    .action-items-tabs-container {
        @apply w-full;
    }
}
