.rc-slider .rc-slider-rail {
    background-color: #d7e1ea;
}

.rc-slider .rc-slider-track {
    @apply bg-secondary;
}

.rc-slider-dot {
    @apply hidden;
}

.rc-slider .rc-slider-handle {
    @apply bg-secondary border-secondary;
}

.rc-slider .rc-slider-handle:active,
.rc-slider .rc-slider-handle:hover,
.rc-slider .rc-slider-handle.rc-slider-handle-click-focused:focus {
    @apply border-secondary;
}
