input[type='radio'][name='paymentCycle'] {

}

.payment-cycle-radio-container {
    @apply relative cursor-pointer w-8;
}

.payment-cycle-radio-container input {
    @apply absolute opacity-0 cursor-pointer;
}

/* Create a custom radio button */
.checkmark {
    @apply absolute top-0 left-0 size-5 rounded-full bg-white border border-secondary box-content cursor-pointer;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    @apply absolute hidden;
    content: "";
}

/* Show the indicator (dot/circle) when checked */
.payment-cycle-radio-container input:checked ~ .checkmark:after {
    @apply block;
}

/* Style the indicator (dot/circle) */
.payment-cycle-radio-container .checkmark:after {
    @apply top-1 left-1 size-3 rounded-full bg-secondary;
}
