.page-container {
    @apply h-full py-10 mx-auto;
    width: 970px;
}

#villain-image-box {
    @apply mr-24 bg-no-repeat;
    height: 500px;
    background-position: center 20px;
}

#villain-text-bubble {
    @apply absolute bg-no-repeat;
    top: -40px;
    right: -100px;
    width: 441px;
    height: 122px;
}

#wanted-box {
    @apply absolute bg-no-repeat top-0 left-0;
    width: 258px;
    height: 350px;
}

#mug-shot {
    @apply absolute bg-no-repeat top-16;
    background-position: center center;
    width: 258px;
    height: 150px;
}

#villain-message-text {
    @apply overflow-hidden;
    margin: 17px 28px 25px 30px;
    font-size: 19px;
    line-height: 25px;
    height: 55px;
}

#wanted-for-message {
    @apply relative text-center font-bold;
    font-variant: small-caps;
    top: 205px;
}

#wanted-for-reason {
    @apply relative m-0 font-bold;
    font-variant: small-caps;
    top: 205px;
    padding: 10px 20px 10px 30px;
    font-size: 13px;
}

#wanted-for-reason li {
    @apply ml-5 mt-0 mb-0 mr-0 p-0;
}

#villain-label {
    @apply absolute bg-no-repeat;
    background-position: center top;
    left: 0;
    top: -35px;
    width: 258px;
    height: 30px;
}
