@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply text-base text-gray-800 leading-normal;
}

p {
    @apply mb-4;
}

input[type='text']:not([id^='react-select-']),
input[type='password'],
input[type='number'],
input[type='date'],
textarea,
select,
.card-container {
    @apply w-full px-3 py-2 text-sm border border-gray-400 rounded-lg outline-none;
}

.react-select .react-select__control {
    @apply w-full px-3 text-sm border border-gray-400 rounded-lg outline-none;
    min-height: 34px;
}

input[type='text']:not([id^='react-select-']):not(:disabled):active,
input[type='password']:not(:disabled):active,
input[type='number']:not(:disabled):active,
textarea:not(:disabled):active,
input[type='text']:not([id^='react-select-']):not(:disabled):focus,
input[type='password']:not(:disabled):focus,
input[type='number']:not(:disabled):focus,
textarea:not(:disabled):focus {
    @apply shadow-outline-active;
}

input[type='text']:not([id^='react-select-']).has-error,
input[type='password'].has-error,
input[type='number'].has-error,
textarea.has-error {
    @apply shadow-outline-error;
}

input[type='text'].search-input:not([id^='react-select-']) {
    @apply pl-10 bg-white bg-no-repeat;
    background-image: url(../images/search.svg);
    background-position: 0.875rem center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.react-datepicker-wrapper {
    @apply w-full;
}

.react-datepicker__input-container input[type='text']:not([id^='react-select-']) {
    @apply pl-12 bg-white bg-no-repeat;
    background-image: url(../images/calendar-primary.svg);
    background-position: 0.875rem center;
}

.react-datepicker__input-container input.clock-input[type='text']:not([id^='react-select-']),
input.clock-input[type='number'] {
    @apply pl-12 bg-white bg-no-repeat;
    background-image: url(../images/clock.svg);
    background-position: 0.875rem center;
}

.react-datepicker__input-container input.no-image[type='text']:not([id^='react-select-']),
input.clock-input[type='number'] {
    @apply pl-3 bg-white bg-no-repeat;
    background-image: none;
}

.react-select .react-select__value-container {
    @apply p-0;
}

.react-select .react-select__placeholder {
    @apply text-gray-400;
}

.react-select.has-error .react-select__control:not(.react-select__control--is-focused) {
    @apply shadow-outline-error;
}

button:focus,
input[type=submit]:focus {
    @apply outline-none;
}

.error-text {
    @apply mt-2 text-alert;
}

.button {
    @apply px-4 py-3 text-sm rounded-lg cursor-pointer;
}

.button-primary {
    @apply button bg-primary text-white;
}

.button-secondary {
    @apply button bg-secondary text-white;
}

.button-alert {
    @apply button bg-alert text-white;
}

.button-white {
    @apply button bg-white text-gray-800;
}

.native-checkbox {
    /* From https://polished.js.org/docs/#hidevisually */
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.avatar {
    @apply rounded-full bg-secondary text-white flex justify-center items-center size-8 text-xs shrink-0;
}

.message {
    @apply px-4 py-2 my-3 border rounded-lg;
}

.notice-message {
    @apply message text-blue-700 bg-blue-100 border-blue-300;
}

.success-message {
    @apply message text-emerald-700 bg-emerald-100 border-emerald-300;
}

.warning-message {
    @apply message text-amber-700 bg-amber-100 border-amber-300;
}

.error-message {
    @apply message text-red-700 bg-red-100 border-red-300;
}

.success-message a,
.warning-message a,
.error-message a {
    @apply font-semibold text-primary hover:underline;
}

.dropdown-item {
    @apply flex items-center p-2 font-bold text-gray-600 rounded-lg cursor-pointer;
}

.dropdown-item.active,
.dropdown-item:hover {
    @apply bg-gray-200 text-primary;
}
