.dashboard-left-column {
    width: 340px;
    @apply shrink-0 2xl:w-1/5;
}

@media screen and (max-width: 1023px) {
    .dashboard-left-column {
        @apply w-full;
    }
}

.dashboard-right-column {
    width: calc(100% - 340px);
    @apply 2xl:w-4/5 pl-12;
}

@media screen and (max-width: 1023px) {
    .dashboard-right-column {
        @apply w-full pl-0;
    }
}

.notes-icon {
    -webkit-mask: url(../images/edit.svg) no-repeat;
    mask: url(../images/edit.svg) no-repeat;
    @apply w-3 h-4 mr-2 bg-gray-600 shrink-0;
}

.checkbox-icon {
    -webkit-mask: url(../images/selected-checkbox.svg) no-repeat;
    mask: url(../images/selected-checkbox.svg) no-repeat;
    @apply size-4 mr-2 bg-gray-600 shrink-0;
}

.total-items-count-container {
    @apply bg-white rounded-lg mb-4 lg:mb-8 flex justify-between items-center py-2 lg:py-5 px-4 lg:px-10;
}

.total-items-count-container .number {
    @apply text-2xl text-gray-800 font-bold;
}

.total-items-count-container .text {
    @apply text-gray-600 font-semibold;
}

.total-items-count-container .icon-container {
    @apply bg-gray-200 rounded-full size-16 flex items-center justify-center ml-auto shrink-0;
}
