.admin-menu-list-item {
    @apply w-full block p-2 rounded-lg font-bold cursor-pointer text-gray-600;
}

.sortable pre {
    @apply block p-2 m-2 border border-gray-600 break-all whitespace-pre-wrap rounded-lg bg-gray-200;
    word-wrap: break-word;
}

.admin-account-properties {
    @apply bg-white p-4 rounded-lg mb-8;
}

.admin-account-properties dt {
    @apply font-bold mb-2;
}

.admin-account-properties dd {
    @apply mb-2;
}

.admin-account-properties dl > dt {
    @apply block float-left clear-left mr-4;
}
