.tags-tooltip .tags-container {
    @apply flex flex-wrap;
    max-width: 825px;
}

.tags-tooltip.rc-tooltip .rc-tooltip-inner {
  @apply bg-white;
}

.tags-tooltip.rc-tooltip.rc-tooltip-placement-top .rc-tooltip-arrow,
.tags-tooltip.rc-tooltip.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.tags-tooltip.rc-tooltip.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  @apply border-t-gray-800 border-opacity-20;
}

.tags-tooltip.rc-tooltip.rc-tooltip-placement-right .rc-tooltip-arrow,
.tags-tooltip.rc-tooltip.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.tags-tooltip.rc-tooltip.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  @apply border-r-gray-800 border-opacity-20;
}

.tags-tooltip.rc-tooltip.rc-tooltip-placement-left .rc-tooltip-arrow,
.tags-tooltip.rc-tooltip.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.tags-tooltip.rc-tooltip.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  @apply border-l-gray-800 border-opacity-20;
}

.tags-tooltip.rc-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.tags-tooltip.rc-tooltip.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.tags-tooltip.rc-tooltip.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  @apply border-b-gray-800 border-opacity-20;
}
