.preview-report {
    min-width: 670px;
    @apply lg:w-1/2 lg:pr-3;
}

@media screen and (max-width: 1023px) {
    .preview-report {
        min-width: auto;
        @apply w-full overflow-x-scroll;
    }
}
