.rc-tooltip .rc-tooltip-inner {
  @apply bg-gray-800;
}

.rc-tooltip.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  @apply border-t-gray-800;
}

.rc-tooltip.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  @apply border-r-gray-800;
}

.rc-tooltip.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  @apply border-l-gray-800;
}

.rc-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  @apply border-b-gray-800;
}
