.navigation-tab {
    @apply flex items-center h-full mx-8 text-white border-t-2 border-black shrink-0;
}

.navigation-tab.active {
    @apply text-secondary border-secondary;
}

.field-validation-error {
    @apply block mt-2 text-sm text-alert;
}

.welcome-button {
    @apply w-9 h-9 rounded-xl text-center border border-gray-400;
    animation-name: blinking;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

.layout-logo {
    height: 69px;
    @apply py-4;
}

@keyframes blinking {
    50% {
        @apply border-gray-800;
    }
}