.dash {
    height: 2px;
    @apply mx-4 w-2 bg-gray-800;
}

.agenda-item-input-container {
    width: 357px;
    @apply mr-3 shrink-0 2xl:w-1/2;
}

@media screen and (max-width: 1023px) {
    .agenda-item-input-container {
        @apply w-full;
    }
}

.agenda-note-input-container {
    width: 325px;
    @apply mr-3;
}

@media screen and (max-width: 1023px) {
    .agenda-note-input-container {
        @apply w-full;
    }
}
